import {geoConfig} from '../../config/GeoConfig'

export const downloadConfig = {
    DESIRED_EXPIRATION_IN_MINUTES: 20,
    downloadStorageKey: 'download-' + geoConfig.getGeo(),
    updateLoadingButton: (loadingButton) => {
        const expiresInMinutes = new Date().getTime() + downloadConfig.DESIRED_EXPIRATION_IN_MINUTES * 60000
        const downloadStorageValue = {
            value: loadingButton,
            expires: expiresInMinutes
        }
        localStorage.setItem(downloadConfig.downloadStorageKey, JSON.stringify(downloadStorageValue))
    },
    getLoadingButton: () => {
        const downloadStorageValue = JSON.parse(localStorage.getItem(downloadConfig.downloadStorageKey))
        if (downloadStorageValue === null) {
            return null
        }

        const shouldExpireValue = new Date().getTime() > downloadStorageValue.expires
        if (shouldExpireValue) {
            downloadConfig.clearLoadingButton()
            return null
        } else {
            return downloadStorageValue.value
        }
    },
    clearLoadingButton: () => {
        localStorage.removeItem(downloadConfig.downloadStorageKey)
    },
}
