import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client'
import {oktaAuth} from '../shared/Okta'
import {setContext} from '@apollo/client/link/context'

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_RETURNS_API_URL,
});

export function createApolloClient() {

    const authLink = setContext((_, { headers }) => {
        const token = oktaAuth.getAccessToken()
        return {
            headers: {
                ...headers,
                authorization: token || '',
            },
        }
    })

    return new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache({
            resultCaching: false,
        }),
        defaultOptions: {
            query: {
                fetchPolicy: 'no-cache',
            },
        },
    })
}
