import { Text } from '@nike/eds'
import { PropsWithChildren } from 'react'

interface FieldProps extends Omit<PropsWithChildren, 'children'>{
    label: any,
    style?: object
    className?: string
    children?: any
}

export function DetailsField({ children, label, style, className }: FieldProps) {
    return (
        <div style={{ margin: '10px', ...style }} className={className}>
            <label style={{ textTransform: 'capitalize', font: "18px 'Nike TG'" }}>{label}</label>
            <Text font="body-2" as="div">
                <span>{children === null || children === '' ? "-" : children}</span>
            </Text>
        </div>
    )
}