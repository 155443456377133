import { Link, Text } from '@nike/eds'
import clsx from 'clsx'
import './Chips.css'
import Chip from '@mui/material/Chip'
import { useState } from 'react'

const multiValueFields = [
    "returnSalesOrderNumbers",
    "soldToCustomers",
    "shipToCustomers",
    "salesOrganisationCodes",
    "itemIds",
    "productIds"
]

export interface ChipValue {
  field: string
  value: any
  stringValue: string
  index?: number
}

export interface ChipsProps {
  allValues: ChipValue[]
  onDelete: (value: ChipValue) => void
  clearFilter: () => void
  className?: string
}

export const Chips = ({ allValues, onDelete, clearFilter, className = '' }: ChipsProps) => {
  const MAX_CHIPS_LENGTH = 8
  const enrichedClassName = clsx('filter-chips', className)

  const [showReturnSalesOrderNumbers, setShowReturnSalesOrderNumbers] = useState<boolean>(false);
  const [showSoldToCustomers, setShowSoldToCustomers] = useState<boolean>(false);
  const [showShipToCustomers, setShowShipToCustomers] = useState<boolean>(false);
  const [showSalesOrganisationCodes, setShowSalesOrganisationCodes] = useState<boolean>(false);
  const [showItemIds, setShowItemIds] = useState<boolean>(false);
  const [showProductIds, setShowProductIds] = useState<boolean>(false);
  const [showAllOther, setShowAllOther] = useState<boolean>(false)

  const returnSalesOrderNumbers = allValues.filter(e => e.field === 'returnSalesOrderNumbers')
  const soldToCustomers = allValues.filter(e => e.field === 'soldToCustomers')
  const shipToCustomers = allValues.filter(e => e.field === 'shipToCustomers')
  const salesOrganisationCodes = allValues.filter(e => e.field === 'salesOrganisationCodes')
  const itemIds = allValues.filter(e => e.field === 'itemIds')
  const productIds = allValues.filter(e => e.field === 'productIds')

  const other = allValues.filter(
    e => !multiValueFields.includes(e.field)
  )

  const createChipsArray = (values: ChipValue[], show: boolean, showAll, field: string) => {
    return (
      <div>
        {values.slice(0, show ? values.length : MAX_CHIPS_LENGTH).map((item, key) => (
          <Chip
            className="customChip"
            variant="filled"
            style={{ backgroundColor: 'var(--eds-color-background-default)' }}
            label={item.stringValue}
            key={`${item.field}-${key}`}
            onDelete={() => {
              onDelete(item)
            }}
          />
        ))}
        {show && values.length > MAX_CHIPS_LENGTH && (
          <Chip
            onClick={() => showAll(false)}
            label={'See Less'}
            variant="outlined"
            style={{ margin: 'var(--eds-space-4)' }}
          />
        )}
        {!show && values.length > MAX_CHIPS_LENGTH && (
          <Chip
            onClick={() => showAll(true)}
            label={`+${values.length - MAX_CHIPS_LENGTH}`}
            variant="outlined"
            style={{ margin: 'var(--eds-space-4)' }}
          />
        )}
      </div>
    )
  }

  return (
    <div className={enrichedClassName}>
      <div className="filter-chips-action">
        <Text font="title-5" className="eds-spacing--mb-8">
          Filters ({allValues?.length})
        </Text>
        <Link as="button" variant="secondary" onClick={clearFilter}>
          Clear All
        </Link>
      </div>

      <div className="filter-chips-group">
        {returnSalesOrderNumbers.length > 0 &&
          createChipsArray(
            returnSalesOrderNumbers,
            showReturnSalesOrderNumbers,
            setShowReturnSalesOrderNumbers,
            'returnSalesOrderNumbers'
          )}

        {soldToCustomers.length > 0 &&
            createChipsArray(
                soldToCustomers,
                showSoldToCustomers,
                setShowSoldToCustomers,
                'soldToCustomers')}

      {shipToCustomers.length > 0 &&
          createChipsArray(
              shipToCustomers,
              showShipToCustomers,
              setShowShipToCustomers,
              'shipToCustomers')}

        {salesOrganisationCodes.length > 0 &&
          createChipsArray(
              salesOrganisationCodes,
              showSalesOrganisationCodes,
              setShowSalesOrganisationCodes,
              'salesOrganisationCodes')}

        {itemIds.length > 0 &&
            createChipsArray(
                itemIds,
                showItemIds,
                setShowItemIds,
                'itemIds')}

        {productIds.length > 0 &&
            createChipsArray(
                productIds,
                showProductIds,
                setShowProductIds,
                'productIds')}


        {other.length > 0 && createChipsArray(other, showAllOther, setShowAllOther, 'other')}
      </div>
    </div>
  )
}

export default Chips
