import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { LoginCallback, SecureRoute, useOktaAuth } from '@okta/okta-react'
import { ToleranceOverview } from './views/tolerance-overview/ToleranceOverview'
import { About } from './views/about/About'
import { LoginError } from './views/login-error/LoginError'
import { extractBooleanFromEnvironmentVariable } from './shared/datautils/environment'
import { hasToleranceReadAccess, hasReturnsReadAccess } from './shared/datautils/authorizationService'
import { useEffect, useState } from 'react'
import { ReturnsOverview } from './views/returns-overview/ReturnsOverview'
import { ReturnsDetail } from './views/returns-detail/ReturnsDetail'

const ReturnsDetailRoute = props => {
    const { search } = useLocation();
    const urlQuery = new URLSearchParams(search);
    const plantCodeParam = urlQuery.get('plantCode');

    return plantCodeParam ? <SecureRoute {...props} /> : <Redirect to="/returns-overview" />;
};

export function Routing () {
    const { authState } = useOktaAuth()
    const history = useHistory()
    const [redirectToUri, setRedirectToUri] = useState<string>('/returns-overview')

    const [returnsReadAccess, setReturnsReadAccess] = useState<boolean>(true)
    const [toleranceReadAccess, setToleranceReadAccess] = useState<boolean>(true)

    useEffect(() => {
        if (authState !== null) {
            setReturnsReadAccess(hasReturnsReadAccess(authState))
            setToleranceReadAccess(hasToleranceReadAccess(authState))
            if (hasReturnsReadAccess(authState)) {
                setRedirectToUri('/returns-overview')
            } else if (hasToleranceReadAccess(authState)) {
                setRedirectToUri('/tolerance-overview')
            } else {
                history.push('/about')
            }
        }
    }, [authState, history])

    return (
        <Switch>
            {
                toleranceReadAccess
                &&
                extractBooleanFromEnvironmentVariable(process.env.REACT_APP_TOLERANCE_ENABLED)
                && <SecureRoute exact path={'/tolerance-overview'} component={ToleranceOverview}/>
            }
            {
                returnsReadAccess
                &&
              <SecureRoute exact path={'/returns-overview'} component={ReturnsOverview}/>
            }
            {
                returnsReadAccess
                &&
              <ReturnsDetailRoute exact
                           path={'/returns-detail/:returnSalesOrderNumber'}
                           render={({ match }) => (
                               <ReturnsDetail
                                   returnSalesOrderNumber={decodeURIComponent(match.params.returnSalesOrderNumber) || ''}/>
                           )}/>
            }
            <SecureRoute exact path={'/about'} component={About}/>
            <Route exact path={'/implicit/callback'}>
                <LoginCallback errorComponent={LoginError}/>
            </Route>
            <Redirect to={redirectToUri}/>
        </Switch>
    )
}
