import {Popover} from '@nike/eds'
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {ReportType} from '../../model/ReportType'
import {DownloadButton} from './DownloadButton'
import {returnsOverviewFilterStore} from '../returns-overview/ReturnsOverviewFilterStore'
import {downloadConfig} from './DownloadConfig'
import {WarningPopoverComponent} from './WarningPopoverComponent'
import {ErrorPopoverComponent} from './ErrorPopoverComponent'

interface MenuPopoverProps extends React.HTMLAttributes<HTMLElement> {
    children?: React.ReactNode,
    isPopoverVisible: boolean,
    setIsPopoverVisible: Dispatch<SetStateAction<boolean>>
}

export const MenuPopoverComponent: React.FC<MenuPopoverProps> = ({children, isPopoverVisible, setIsPopoverVisible}) => {

    const ALLOWED_PERIOD_OF_DAYS_FOR_REPORT = 30

    const [isDisabled, setIsDisabled] = useState(!!downloadConfig.getLoadingButton())
    const [isWarningPopoverVisible, setIsWarningPopoverVisible] = useState(false)
    const [isErrorPopoverVisible, setIsErrorPopoverVisible] = useState(false)
    const [warningMessage, setWarningMessage] = useState('')

    // Clear popover state on page refresh if loading is in process
    useEffect(() => {
        if (isDisabled) {
            window.onbeforeunload = function() {
                downloadConfig.clearLoadingButton()
                return true;
            };
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [isDisabled]);

    const shouldStartDownload = () => {
        setIsDisabled(true)
        const filterData = returnsOverviewFilterStore.getFinanceFilterStoredData()
        if (!isValidFilterData(filterData)) {
            setIsWarningPopoverVisible(true)
            clearPopoverState()
            return false
        }
        return true
    }

    const isValidFilterData = filterData => {
        if (!filterData || (!filterData.receivedDateTimeFrom && !filterData.returnSalesOrderNumbers)) {
            setWarningMessage('Please, apply "Received datetime" or "RSO number" filters before downloading')
            return false
        } else if (filterData.returnSalesOrderNumbers) {
            return true
        }

        const datetimePeriod = calculateDays(filterData.receivedDateTimeFrom, filterData.receivedDateTimeTo)
        if (filterData.receivedDateTimeFrom && datetimePeriod > ALLOWED_PERIOD_OF_DAYS_FOR_REPORT) {
            setWarningMessage(`"Received datetime" date range should not exceed ${ALLOWED_PERIOD_OF_DAYS_FOR_REPORT} days`)
            return false
        }
        return true
    }

    const calculateDays = (startDate, endDate) => {
        const start = new Date(startDate)
        const end = endDate ? new Date(endDate) : new Date()
        const timeDifference = Math.abs(end.getTime() - start.getTime())
        return Math.ceil(timeDifference / (1000 * 3600 * 24))
    }

    const initErrorPopover = () => {
        setIsErrorPopoverVisible(true)
        clearPopoverState()
    }

    const clearPopoverState = () => {
        setIsPopoverVisible(false)
        setIsDisabled(false)
        downloadConfig.clearLoadingButton()
    }

    const header =
    <h1 className='eds-type--title-5'>
        Select Report Type
    </h1>

    const body =
    <div style={{display: 'flex', flexDirection: 'column', width: '256px'}}>
        <DownloadButton onClick={() => shouldStartDownload()} reportType={ReportType.RSO} isDisabled={isDisabled} setIsDisabled={setIsDisabled} initErrorPopover={initErrorPopover}/>
        <DownloadButton onClick={() => shouldStartDownload()} reportType={ReportType.MATERIAL_INVENTORY} isDisabled={isDisabled} setIsDisabled={setIsDisabled} initErrorPopover={initErrorPopover}/>
        <DownloadButton onClick={() => shouldStartDownload()} reportType={ReportType.MATERIAL_FINANCE} isDisabled={isDisabled} setIsDisabled={setIsDisabled} initErrorPopover={initErrorPopover}/>
    </div>

    const onClickOutsidePopover = () => {
        setIsPopoverVisible(false)
        if (downloadConfig.getLoadingButton() === null) {
            setIsDisabled(false)
        }
    }

    return (
        // eslint-disable-next-line
        <ErrorPopoverComponent isPopoverVisible={isErrorPopoverVisible} setIsPopoverVisible={setIsErrorPopoverVisible}>
            <WarningPopoverComponent isPopoverVisible={isWarningPopoverVisible} setIsPopoverVisible={setIsWarningPopoverVisible} message={warningMessage}>
                <Popover
                    isOpen={isPopoverVisible}
                    onClickOutside={onClickOutsidePopover}
                    headerSlot={header}
                    bodySlot={body}
                >
                    {children}
                </Popover>
            </WarningPopoverComponent>
        </ErrorPopoverComponent>
    )
}
