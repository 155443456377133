import React, { useState } from 'react'
import {Button, Tooltip} from '@nike/eds'
import {CaretDown, CaretUp} from '@nike/nike-design-system-icons'
import {MenuPopoverComponent} from './MenuPopoverComponent'

export function ReturnsDownloadComponent() {

    const [isPopoverVisible, setIsPopoverVisible] = useState(false)

    const tooltipBody =
    <p style={{ width: "250px" }}>
        Downloading results are based on filters: "Received datetime", "RSO Number", "Sold-To Customer", "Ship-To Customer", "RSO Status", "Plant Code"
    </p>

    return (
        <div className="flex-shrink-0">
            <MenuPopoverComponent
                isPopoverVisible={isPopoverVisible}
                setIsPopoverVisible={setIsPopoverVisible}
            >
                <Tooltip
                    bodySlot={tooltipBody}
                    placement="left"
                    enableFocus
                >
                    <Button
                        style={{ marginTop: 'var(--eds-space-8)', marginRight: 'var(--eds-space-8)' }}
                        onClick={() => setIsPopoverVisible(!isPopoverVisible)}
                        afterSlot={isPopoverVisible ? <CaretUp /> : <CaretDown />}
                    >
                        Download
                    </Button>
                </Tooltip>
            </MenuPopoverComponent>
        </div>
    )
}
