import {ToleranceOverviewFilterFields} from '../../model/ToleranceOverviewModels'
import {SearchInput} from '../../shared/form/SearchInput'
import {debounce} from 'lodash'
import {useRef} from 'react'


export interface ToleranceFilterProps {
    currentFilterState: Object,
    handleFilter: (filterData: ToleranceOverviewFilterFields) => void,
}

export function ToleranceFilter({currentFilterState, handleFilter}: ToleranceFilterProps) {

    const handleFilterChangeDebounced = useRef(debounce(async (event) => {
            handleFilterChanged(event)
        }, 500)
    ).current

    const handleFilterChanged = (event) => {
        const newFilter: Object = {
            ...currentFilterState,
            [event.target.name]:
                event.target.value !== undefined &&
                event.target.value !== ''
                    ? event.target.value
                    : undefined
        }

        handleFilter(newFilter)
    }

    return (
        <div className="flex-row">
            <SearchInput name="soldToNumber" label="Sold To Number" onChange={handleFilterChangeDebounced}
                         style={{width: '25%'}}/>
        </div>
    )
}