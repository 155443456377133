import { Button } from '@nike/eds'
import { useHistory } from 'react-router-dom'
import { ArrowLeft } from '@nike/nike-design-system-icons'

export function BackButton({ style = undefined, ...otherProps }) {
    const history = useHistory()

    return (
        <Button
            style={{ marginRight: 'var(--eds-space-24)', display: 'inline-flex', ...style }}
            onClick={() => history.goBack()}
            {...otherProps}
        >
            <ArrowLeft />
        </Button>
    )
}