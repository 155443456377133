import { SearchPlantCodesDocument } from '../../generated/graphql'
import { useQuery } from '@apollo/client'
import { geoConfig } from '../../config/GeoConfig'

export function PlantCodeOptions() {

    const plantCodeResult = useQuery(SearchPlantCodesDocument, {
        variables: {
            geoAllocation: geoConfig.getGeo()
        }
    })

    return  plantCodeResult?.data?.searchPlantCodes?.map(it => ({'value': it.plantCode, 'label': it.plantCode }))
}