import {ToleranceFilter} from './ToleranceFilter'
import {ToleranceOverviewFilterFields} from '../../model/ToleranceOverviewModels'
import useQueryState from '../../shared/hooks/useQueryState'
import {useQuery} from '@apollo/client'
import {SearchTolerancesDocument} from '../../generated/graphql'
import {ToleranceOverviewTableProps, ToleranceTable} from './ToleranceTable'
import {ApolloResult} from '../../shared/component/ApolloResult'
import {useState} from 'react'
import {ToleranceDeleteModal} from './ToleranceDeleteModal'
import {ToleranceFormModal} from './ToleranceFormModal'
import {geoConfig} from '../../config/GeoConfig';

export function ToleranceOverview() {
    const defaultQueryState = {
        sortBy: [
            {id: 'soldToNbr', desc: false}
        ]
    }
    const {
        state: queryState,
        setFilter,
        gotoPage,
        setPageSize,
        toggleSortBy,
    } = useQueryState<ToleranceOverviewFilterFields>({...defaultQueryState})

    const {loading: isFetching, error, data, networkStatus} = useQuery(SearchTolerancesDocument, {
        variables: {
            searchToleranceInput: {
                from: queryState.pageNumber * queryState.pageSize,
                size: queryState.pageSize,
                sorting: queryState.sortBy,
                geoAllocation: geoConfig.getGeo(),
                ...queryState?.filter
            },
            geoAllocation: geoConfig.getGeo()
        }
    })

    const [isDeleteModalVisible, setVisibilityDeleteModal] = useState(false)
    const [isFormModalVisible, setVisibilityFormModal] = useState(false)
    const [selectedTolerance, setSelectedTolerance] = useState({})

    const filterTolerance = (filterData: ToleranceOverviewFilterFields) => {
        setFilter(filterData)
    }

    const toleranceOverviewTableProps: ToleranceOverviewTableProps = {
        queryState,
        gotoPage,
        setPageSize,
        toggleSortBy,
        isSuccess: !isFetching && !error,
        isFetching,
        data: {
            data: data ? data.searchTolerances?.toleranceDocuments : [],
            ...data?.searchTolerances?.paging
        },
        setSelectedTolerance,
        setVisibilityDeleteModal,
        setVisibilityFormModal
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 'var(--eds-space-8)'
            }}
        >
            <ToleranceFilter currentFilterState={queryState?.filter} handleFilter={filterTolerance}/>
            <ApolloResult networkStatus={networkStatus} error={error} loadingDataName="tolerances">
                <ToleranceTable {...toleranceOverviewTableProps}/>
                <ToleranceFormModal isVisible={isFormModalVisible} selectedTolerance={selectedTolerance}
                                    setVisibilityFormModal={setVisibilityFormModal}/>
                {selectedTolerance &&
                    <ToleranceDeleteModal isVisible={isDeleteModalVisible} selectedTolerance={selectedTolerance}
                                          setVisibilityDeleteModal={setVisibilityDeleteModal}/>
                }

            </ApolloResult>
        </div>
    )
}
