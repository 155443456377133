import {Snack} from '@nike/eds'
import React from 'react'

interface InfoSnackProps extends React.HTMLAttributes<HTMLElement> {
    children?: React.ReactNode
}

export const InfoSnack: React.FC<InfoSnackProps> = ({children, className}) => {
    return (
        <Snack className={className} id="info-snack" status="info" onDismiss={(id) => {}} hideDismiss={true}>
            {children}
        </Snack>
    )
}
