import { NikeApp } from '@nike/nike-design-system-icons'
import './Footer.css'

export function Footer() {
    return (
        <div className="footer">
            <NikeApp
                title="Nike logo"
                color="black"
                width="56px"
                height="64px"
                // Adding styling to align the footer icon with pagination at the bottom
                style={{marginRight: "75px"}}
            />
        </div>
    )
}
