import 'normalize.css'
import '@nike/eds/dist/index.css'
import './styles/Index.css'
import './styles/Fonts.css'
import './styles/Flex.css'

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
