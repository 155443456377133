import {geoConfig} from '../../config/GeoConfig'

export const returnsOverviewFilterStore = {
    storageKey: 'financeFilterStoredData-' + geoConfig.getGeo(),
    emptyFormData : {
        returnSalesOrderNumbers: [],
        soldToCustomers: [],
        shipToCustomers: [],
        salesOrganisationCodes: [],
        approvalDateFrom: '',
        approvalDateTo: '',
        receivedDateTimeFrom: '',
        receivedDateTimeTo: '',
        plantCodes: [],
        customerType: '',
        status: [],
    },
    getFinanceFilterStoredData: () => {
        return JSON.parse(localStorage.getItem(returnsOverviewFilterStore.storageKey))
    },
    getFinanceFilterStoredDataOrEmptyData: () => {
        return JSON.parse(localStorage.getItem(returnsOverviewFilterStore.storageKey)) ?? returnsOverviewFilterStore.emptyFormData
    },
    setFinanceFilterStoredData: (financeFilterData) => {
        localStorage.setItem(returnsOverviewFilterStore.storageKey, JSON.stringify(financeFilterData))
    },
    updateFinanceFilterStoredData: (fieldName, fieldValue) => {
        let storedData = returnsOverviewFilterStore.getFinanceFilterStoredData()
        if (storedData) {
            storedData[fieldName] = fieldValue
            returnsOverviewFilterStore.setFinanceFilterStoredData(storedData)
        }
    },
    getFieldValue: (financeFiltersData, fieldName) => {
        return financeFiltersData[fieldName] ?? returnsOverviewFilterStore.emptyFormData[fieldName]
    },
    clearStorage: () => {
        localStorage.setItem(returnsOverviewFilterStore.storageKey, JSON.stringify({}))
    },
}
