import {useQuery} from "@apollo/client";
import {SearchPlantCodesDocument} from "../../generated/graphql";
import {geoConfig} from "../../config/GeoConfig";
import {plantCodesConfig} from "../../config/PlantCodesConfig";

export function PlantCodeUpdateUtil() {

    const {data, error, networkStatus} = useQuery(SearchPlantCodesDocument, {
        variables: {
            geoAllocation: geoConfig.getGeo()
        }
    })

    const result = data?.searchPlantCodes?.map(it => it.plantCode)
    plantCodesConfig.updatePlantCodes(result)
    return {plantCodes: result, plantCodeRequestErrors: error, plantCodeRequestNetworkStatus: networkStatus}
}
