import { NetworkStatus, QueryResult } from '@apollo/client'
import { Spinner } from '@nike/eds'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorSnack } from './ErrorSnack'
import {Text} from "@nike/eds"

export interface ApolloResultProps extends React.PropsWithChildren<
  Pick<QueryResult, 'networkStatus' | 'error'>
> {
    loadingDataName?: String
}

export function ApolloResult({
  networkStatus,
  error,
  children,
    loadingDataName = "returns"
}: ApolloResultProps): React.ReactElement {
  if (networkStatus === NetworkStatus.loading) {
    return (
        <div className="flex-row flex-wrap content-center justify-items-center">
            <div style={{marginRight: 'var(--eds-space-8)'}}>
                <Spinner size="large"/>
            </div>
            <div>
                <Text>Loading {loadingDataName}</Text>
            </div>
        </div>
    )
  }
  if (networkStatus === NetworkStatus.error) {
    return <ErrorSnack error={error} />
  }
  return (
    <ErrorBoundary resetKeys={[networkStatus]} FallbackComponent={ErrorSnack}>
      {children}
    </ErrorBoundary>
  )
}
