import { useMemo } from 'react'
import { Icon, Text, Button } from '@nike/eds'
import { TableLayout, useServerDataTable } from '../../shared/table'
import { InfoSnack } from '../../shared/component/InfoSnack'
import { date } from '../../shared/datautils/formatter'
import { useOktaAuth } from '@okta/okta-react'
import { hasToleranceEditAccess } from '../../shared/datautils/authorizationService'

const useToleranceOverviewTable = (
    queryState,
    gotoPage,
    setPageSize,
    toggleSortBy,
    isSuccess,
    data,
    setSelectedTolerance, setVisibilityDeleteModal, setVisibilityFormModal
) => {

    const { authState } = useOktaAuth()

    const columns = useMemo(
        () => [
            {
                Header: () => <div>Sold To Number</div>,
                accessor: 'soldToNbr',
                Cell: item => <div>{item.value}</div>,
            },
            {
                Header: () => <div>Sold To Name</div>,
                accessor: 'soldToName',
                Cell: item => <div>{item.value}</div>,
            },
            {
                Header: () => <div>Group</div>,
                accessor: 'group',
                Cell: item => <div>{item.value ? item.value : '-'}</div>,
            },
            {
                Header: () => <div>Full tolerant</div>,
                accessor: 'fullTolerance',
                Cell: item => <div>{item.value ? 'Yes' : 'No'}</div>,
            },
            {
                Header: () => <div>Last update</div>,
                accessor: 'updatedAt',
                Cell: item => <div>{item.value ? date(item.value, true) : '-'}</div>,
            },
            {
                Header: () => <div>Updated by</div>,
                accessor: 'updatedBy',
                Cell: item => <div>{item.value ? item.value : '-'}</div>,
            },
            {
                id: 'manageColumn',
                Header: () => <div></div>,
                accessor: '/',
                Cell: item =>
                    <div>
                        <Icon
                            name="Edit"
                            className="eds-spacing--mr-32 cursor-pointer"
                            onClick={() => {
                                setVisibilityFormModal(true)
                                setSelectedTolerance(item.row.original)
                            }}
                        />
                        <Icon
                            name="Delete"
                            className="cursor-pointer"
                            onClick={() => {
                                setVisibilityDeleteModal(true)
                                setSelectedTolerance(item.row.original)
                            }}
                        />
                    </div>
            }
        ],
        [setSelectedTolerance, setVisibilityDeleteModal, setVisibilityFormModal]
    )

    const hiddenColumns = !hasToleranceEditAccess(authState) ? ['manageColumn'] : []

    const initialState = { hiddenColumns }

    return useServerDataTable({
        queryState,
        overrides: {
            gotoPage,
            setPageSize,
            toggleSortBy,
        },
        columns,
        isSuccess,
        data,
        initialState
    })
}

export function ToleranceTable ({
    queryState, gotoPage, setPageSize,
    toggleSortBy, isFetching, isSuccess, data,
    setSelectedTolerance, setVisibilityDeleteModal, setVisibilityFormModal
}: ToleranceOverviewTableProps) {
    const tableInstance = useToleranceOverviewTable(
        queryState,
        gotoPage,
        setPageSize,
        toggleSortBy,
        isSuccess,
        data,
        setSelectedTolerance,
        setVisibilityDeleteModal,
        setVisibilityFormModal
    )
    const { authState } = useOktaAuth()
    return (
        <div className="position-relative">
            { hasToleranceEditAccess(authState) &&
                <Button className="float-right position-absolute" style={{ right: 0 }} onClick={() => {
                    setVisibilityFormModal(true)
                    setSelectedTolerance(null)
                }} variant="primary"
                        type="submit" size="small">
                    New <Icon name="Plus"/>
                </Button>
            }
            {
                !isFetching && tableInstance.rows.length === 0
                    ? <InfoSnack className="eds-spacing--mt-32 w-100">
                        <Text font="title-5" as="h5">
                            No records were found that match your filter criteria
                        </Text>
                    </InfoSnack>
                    : <TableLayout
                        {...tableInstance}
                        isFetching={isFetching}
                        loadingDataName="tolerances"
                        className="-striped -highlight"
                    />
            }
        </div>

    )
}

export interface ToleranceOverviewTableProps {
    queryState: any
    gotoPage: (pageNumber) => void
    setPageSize: (pageSize) => void
    toggleSortBy: (id, sortByDesc) => void
    isFetching: boolean
    isSuccess: boolean
    data: object
    totalResources?: number
    setVisibilityDeleteModal: (boolean) => void
    setVisibilityFormModal: (boolean) => void
    setSelectedTolerance: (object) => void
}
