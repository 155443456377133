import { Card } from '@nike/eds'
import React from 'react'
import './About.css'

export function About() {
  return (
    <Card padding={32}>
      <ul className="about-list">
        <li>
          <strong>Application:</strong> {process.env.REACT_APP_NAME}
        </li>
        <li>
          <strong>Version:</strong> {process.env.REACT_APP_VERSION}
        </li>
        <li>
          <strong>React version:</strong> {React.version}
        </li>
      </ul>
    </Card>
  )
}
