import { ApolloError } from '@apollo/client'
import { GraphQLErrors } from '@apollo/client/errors'
import { Snack } from '@nike/eds'

export function ErrorSnack({ error }: { error: ApolloError | Error | undefined }) {
  function isGraphQLError(error: ApolloError | Error | undefined): error is ApolloError {
    return (
      !!error &&
      typeof error === 'object' &&
      'graphQLErrors' in error &&
      !!error.graphQLErrors?.length
    )
  }

  function showGraphQLErrors(graphQLErrors: GraphQLErrors) {
    return graphQLErrors.map((e, i) =>
      e.path ? (
        <p key={e.path[0]}>
          {e.path[0]}: {e.message}
        </p>
      ) : (
        <p key={i}>{e.message}</p>
      )
    )
  }

  return (
    <Snack id="error-snack" onDismiss={(id) => {}} hideDismiss={true} status="error">
      <h1>Error</h1>
      {isGraphQLError(error) ? (
        showGraphQLErrors(error.graphQLErrors)
      ) : (
        <p>{error?.message || 'Unknown'}</p>
      )}
    </Snack>
  )
}
