import { TextArea as EdsTextArea } from '@nike/eds'
import { useController, UseControllerProps } from "react-hook-form";

export interface TextAreaProps extends
  Omit<React.ComponentPropsWithoutRef<typeof EdsTextArea>, 'id' | 'name' | 'value' | 'defaultValue' | 'onChange' | 'onBlur'>,
  Omit<UseControllerProps, 'control'> {
  control: any
}

export const TextArea = ({ name, control, label, rules = undefined, ...rest }: TextAreaProps) => {
  const {
    field: { onChange, ...fieldProps },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  return (
    <EdsTextArea
      id={name}
      {...fieldProps}
      onChange={e => onChange(e.target.value)}
      label={label}
      hasErrors={!!error}
      errorMessage={error?.message}
      {...rest}
    />
  )
}