import { useCallback } from 'react'
import { useTable, usePagination, useSortBy, TableOptions, TableInstance } from 'react-table'
import { QueryState } from '../hooks/useQueryState'

export interface ServerDataTableOptions<D extends Record<string, unknown>, Filter>
    extends Omit<TableOptions<D>, 'data'> {
    isSuccess: boolean
    overrides: Partial<TableInstance<D>>
    initialState?: object
    queryState: QueryState<Filter>
    data: {
        totalPages: number
        totalResources: number
        data: D[]
    }
}

interface ServerDataTableInstance<D extends Record<string, unknown>> extends TableInstance<D> {
    totalResources: number
}

const useServerDataTable = <D extends Record<string, unknown>, F> ({
    columns,
    isSuccess,
    data,
    // used to override some tableInstance properties
    overrides = {},
    // other extra properties
    queryState,
    initialState = {}
}: ServerDataTableOptions<D, F>): ServerDataTableInstance<D> => {
    // The state is used to provide utilities, as we control the pagination, we need to control the table state too
    // see https://react-table.tanstack.com/docs/faq#how-can-i-manually-control-the-table-state
    // NOTE: this gives a false ESLint react-hooks/exhaustive-deps warning
    const useControlledState = useCallback(
        state => {
            return {
                ...state,
                pageIndex: queryState.pageNumber,
                pageSize: queryState.pageSize,
                sortBy: queryState.sortBy,
            }
        },
        [queryState]
    )

    // Use the state and functions returned from useTable to build your UI
    const tableInstance = useTable<D>(
        {
            columns,
            data: isSuccess ? data.data : [],
            manualPagination: true,
            manualSortBy: true,
            useControlledState,
            pageCount: isSuccess ? data.totalPages : 0,
            initialState
        },
        useSortBy,
        usePagination
    )

    return {
        ...tableInstance,
        ...overrides,
        totalResources: isSuccess ? data.totalResources : 0,
    }
}

export default useServerDataTable
