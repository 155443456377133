import {Text, TextField} from '@nike/eds'
import { HTMLAttributes } from 'react'

export interface MultilineInputProps extends HTMLAttributes<HTMLInputElement> {
    name: string
    label: string
}

export function SearchInput({ name, label, ...otherProps }: MultilineInputProps) {
    return (
        <div style={{width: "100%"}}>
            <Text font="title-6" className="eds-spacing--mb-12">{label}</Text>
            <TextField
                id={`${name}Input`}
                label={label}
                hideLabel={true}
                name={name}
                {...otherProps}
            />
        </div>
    )
}
