import {Popover, StatusIcon} from '@nike/eds'
import React, {Dispatch, SetStateAction} from 'react'

interface WarningPopoverProps extends React.HTMLAttributes<HTMLElement> {
    children?: React.ReactNode,
    isPopoverVisible: boolean,
    setIsPopoverVisible: Dispatch<SetStateAction<boolean>>,
    message: String
}

export const WarningPopoverComponent: React.FC<WarningPopoverProps> = ({children, isPopoverVisible, setIsPopoverVisible, message}) => {

    const header =
    <h1 className='eds-type--title-5'>
        <StatusIcon status='warning' statusIconSize='s' enableFocus /> Warning message
    </h1>

    const body = <p style={{ width: '256px' }}>{message}</p>

    const onClickOutsidePopover = () => {
        setIsPopoverVisible(false)
    }

    return (
        <Popover
            isOpen={isPopoverVisible}
            onClickOutside={onClickOutsidePopover}
            headerSlot={header}
            bodySlot={body}
        >
            {children}
        </Popover>
    )
}
