import { Text, Link } from '@nike/eds'
import { Divider } from '@mui/material'
import './InputGroup.css'

interface InputGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string
  clearable: boolean
  onClickClear?: () => void
}

export const InputGroup: React.FC<InputGroupProps> = ({
  label,
  clearable,
  onClickClear = () => console.log(`I'm here but I don't do anything`),
  children,
}) => {
  return (
    <div className={`custom-input-group`}>
      <div
        className={`custom-input-group__header flex-row content-space-between justify-items-center`}
      >
        <Text font="title-6">{label}</Text>
        {clearable && (
          <Link onClick={() => onClickClear()} variant="secondary" type="button" as="button">
            Clear
          </Link>
        )}
      </div>
      <Divider style={{ marginTop: 'var(--eds-space-8)', marginBottom: 'var(--eds-space-16)' }} />
      <div>{children}</div>
    </div>
  )
}
