import React from 'react'
import {Icon, Tooltip} from '@nike/eds'

interface TableHeaderWithTooltipProps {
    toolTipContent?: React.ReactNode,
    headerContent?: React.ReactNode
}

export function TableHeaderWithTooltip({toolTipContent, headerContent}: TableHeaderWithTooltipProps) {
    return (
        <Tooltip
            bodySlot={<div style={{color: '#FFFFFF', fontWeight: 'normal', marginRight: 'var(--eds-space-4)'}}>
                {toolTipContent}
            </div>}
            isDark={true}
            placement={'bottom'}
        >
            <div  className="flex-row justify-items-center  cursor-pointer">
                <span className="eds-spacing--pr-16 flex-row f"
                      style={{color: 'var(--eds-color-text-secondary)'}}>{headerContent}
                    <Icon style={{position: 'relative', top: "5px", left: "2px", minWidth: "15px"}} name="InfoCircle" size="s"/>
                </span>
            </div>
        </Tooltip>
    )
}