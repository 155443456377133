import React from 'react'
import './LoginError.css'
import { Card } from '@nike/eds'

export function LoginError (props: any) {
    console.log(`Error: ${props.error.name} [${props.error.errorCode}] - ${props.error.message}`)
    return (
        <Card padding={32}>
            <ul className="LoginError">
                <p className="LoginError-p">Something went wrong during the login. You are probably missing access to
                    the Returns UI.</p>
                <p className="LoginError-p">To gain access you need to request access to at least once of the following
                    AD Groups in IDLocker:
                    <li><strong>For EMEA region:</strong></li>
                    <li><strong>App.global.returnsPlatform.emea.inventory.read</strong></li>
                    <li><strong>App.global.returnsPlatform.emea.finance.read</strong></li>
                    <li><strong>App.global.returnsPlatform.emea.tolerance.read</strong></li>
                    <li><strong>App.global.returnsPlatform.emea.tolerance.edit</strong></li>
                    <li><strong>For APLA region:</strong></li>
                    <li><strong>App.global.returnsPlatform.apla.inventory.read</strong></li>
                    <li><strong>App.global.returnsPlatform.apla.finance.read</strong></li>
                    <li><strong>App.global.returnsPlatform.apla.tolerance.read</strong></li>
                    <li><strong>App.global.returnsPlatform.apla.tolerance.edit</strong></li>
                </p>

            </ul>
        </Card>
    )
}
