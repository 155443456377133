import { Text } from '@nike/eds'
import Divider from '@mui/material/Divider'
import { useWatch } from 'react-hook-form'
import { TextField } from './'
import './DatePicker.css'

interface DatePickerProps {
  from: DatepickerDate
  to: DatepickerDate
  title: string
  control: any
}

interface DatepickerDate {
  name: string
}

export const DatePicker = ({ control, from, to, title }: DatePickerProps) => {
  const fromValue = useWatch({ control, name: from.name })
  const toValue = useWatch({ control, name: to.name })
  return (
    <>
      <Text font="title-6">{title}</Text>

      <Divider style={{ marginBottom: 'var(--eds-space-8)' }} />

      <div
        className="flex-row flex-wrap content-start justify-items-center"
        style={{ gap: 'var(--eds-space-8)' }}
      >
        <div className="flex-column flex-grow-1">
          <TextField
            name={from.name}
            control={control}
            type="date"
            label=""
            placeholder="From"
            aria-label={`From ${title}`}
            max={toValue}
            rules={{
              max: { value: toValue, message: `The value must be ${toValue} or earlier` }
            }}
          />
        </div>
        <div className="flex-column flex-grow-1">
          <TextField
            name={to.name}
            control={control}
            type="date"
            label=""
            placeholder="To"
            aria-label={`To ${title}`}
            min={fromValue}
            rules={{
              min: { value: fromValue, message: `The value must be ${fromValue} or later` }
            }}
          />
        </div>
      </div>
    </>
  )
}
