import Select, {SelectChangeEvent} from '@mui/material/Select';
import {MenuItem} from '@mui/material';
import {useState} from 'react'
import {geoConfig} from '../../config/GeoConfig';


export function GeoSelect() {
    const [geo, setGeo] = useState(() => {
        return geoConfig.getGeo()
    });

    const handleChange = (event: SelectChangeEvent) => {
        let geo = event.target.value as string;
        setGeo(geo)
        geoConfig.updateGeo(geo)
        window.location.reload()
    };

    return (
        <Select
            value={geo}
            onChange={handleChange}
            variant={'standard'}
            disableUnderline
            sx={{
                '.MuiSvgIcon-root ': {
                    fill: 'white',
                    position: 'absolute',
                    top: '-2px',
                },
                '.MuiInputBase-input ': {
                    position: 'inherit',
                    bottom: '5px',
                    color: 'white',
                },
                '.MuiInput-root': {
                    position: 'relative',
                }
            }}
        >
            {geoConfig.geoOptions.map(it => (
                <MenuItem key={it} value={it}>
                    {it}
                </MenuItem>
            ))}
        </Select>
    )
}