
export const plantCodesConfig = {
    plantCodesKey : "plantCodes",

    updatePlantCodes: (plantCodes) => {
        localStorage.setItem(plantCodesConfig.plantCodesKey, plantCodes);
    },
    getPlantCodes: () => {
        return localStorage.getItem(plantCodesConfig.plantCodesKey).split(',')
    }
}