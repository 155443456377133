import { format, parseISO } from 'date-fns'

export interface Formatter {
  (data: any, options?: object): any
}

export function date(date, showTime = false) {
  if (date && date !== 'false') {
    let toFormat = date
    if (typeof date === 'string') {
      toFormat = parseISO(date)
    }
    const formatTemplate = showTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'
    return format(toFormat, formatTemplate)
  }
  return ''
}

export function bool(value: any) {
  return value ? '☒' : '☐'
}

export function list(value, { separator = ', ', format = defaultFormatter } = {}) {
  return value
    ? value
        .flat(Infinity)
        .map(it => format(it))
        .join(separator)
    : ''
}

export function defaultFormatter(value) {
  if (value && typeof value === 'object') {
    return value.toString()
  }
  if (typeof value === 'boolean') {
    return bool(value)
  }

  if (Array.isArray(value)) {
    return list(value)
  }
  return value === null || value === undefined ? '' : value
}

