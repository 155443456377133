import React from 'react'
import { Select as EdsSelect } from "@nike/eds"
import { useController, UseControllerProps } from "react-hook-form"

export interface SelectProps extends
    Omit<React.ComponentPropsWithoutRef<typeof EdsSelect>, 'id' | 'name' | 'value' | 'defaultValue' | 'onChange' | 'onBlur'>,
    Omit<UseControllerProps, 'control'> {
    control: any
}

export const MultiselectDropdown = ({name, control, label, rules = undefined, ...rest}: SelectProps) => {
    const {
        field: {onChange, ...fieldProps},
        fieldState: { error }
    } = useController({
        name,
        control,
        rules
    })

    const errorMessage = error && error.message && error.message !== "" ? error.message : "Please fill this field in correctly"

    return (
        <div style={{ width: "100%", maxWidth: "370px" }}>
            <EdsSelect
                id={name}
                {...fieldProps}
                onChange={onChange}
                label={label}
                hasErrors={!!error}
                errorMessage={errorMessage}
                isMulti
                {...rest}
            />
        </div>
    )
}