import {TabGroup, Tab} from '@nike/eds'
import {FinanceDetailProductTable} from './FinanceDetailProductTable'
import {InventoryDetailProductTable} from './InventoryDetailProductTable'
import {InventoryDetailItemTable} from './InventoryDetailItemTable'
import React, {useState} from 'react'
import {FinanceDetailItemTable} from './FinanceDetailItemTable'
import {detailTabConfig} from './DetailTabConfig'
import {DETAIL_TABS} from '../constant/DetailTabEnum'

export interface ReturnsDetailTabGroupProps {
    returnSalesOrderNumber: String,
    plantCode: String,
    creditingOnProductLevel: boolean,
    updateDetailUnits: (id) => void
}

export function ReturnsDetailTabGroup({returnSalesOrderNumber, plantCode, creditingOnProductLevel, updateDetailUnits}: ReturnsDetailTabGroupProps) {
    const [activeId, setActiveId] = useState(detailTabConfig.getDetailTab())

    const handleChange = (event) => {
        setActiveId(event.target.id)
        detailTabConfig.updateDetailTab(event.target.id)
        updateDetailUnits(event.target.id)
    }

    return (
        <div>
            <TabGroup
                activeId={activeId}
                name="tab-button-group"
                onChange={handleChange}
                className="eds-spacing--ml-12 eds-spacing--mt-12 eds-spacing--mb-12"
            >
                <Tab id={DETAIL_TABS.FINANCE}>
                    Creditable/non-creditable Inventory
                </Tab>
                <Tab id={DETAIL_TABS.PRODUCT}>
                    Products
                </Tab>
                <Tab id={DETAIL_TABS.ITEM}>
                    Items
                </Tab>
            </TabGroup>

            {
                activeId === DETAIL_TABS.FINANCE && creditingOnProductLevel &&
                <FinanceDetailProductTable returnSalesOrderNumber={returnSalesOrderNumber} plantCode={plantCode} />
            }

            {
                activeId === DETAIL_TABS.FINANCE && !creditingOnProductLevel &&
                <FinanceDetailItemTable returnSalesOrderNumber={returnSalesOrderNumber} plantCode={plantCode} />
            }

            {
                activeId === DETAIL_TABS.PRODUCT &&
                <InventoryDetailProductTable returnSalesOrderNumber={returnSalesOrderNumber} plantCode={plantCode} />
            }

            {
                activeId === DETAIL_TABS.ITEM &&
                <InventoryDetailItemTable returnSalesOrderNumber={returnSalesOrderNumber} plantCode={plantCode} />
            }
        </div>
    )
}
