export const geoConfig = {
    geoStorageKey: 'geo',
    geoOptions: ['EMEA', 'APLA'],
    updateGeo: (geo) => {
        localStorage.setItem(geoConfig.geoStorageKey, geo)
    },
    getGeo: () => {
        return localStorage.getItem(geoConfig.geoStorageKey) || geoConfig.geoOptions[0]
    },
};
