import {Button, Modal, Snack, Text} from '@nike/eds'
import {Tolerance} from '../../model/ToleranceOverviewModels'
import {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {TextField} from '../../shared/react-form'
import {Dropdown} from '../../shared/react-form/Dropdown'
import {useMutation} from '@apollo/client'
import {InsertToleranceConfigDocument, UpdateToleranceConfigDocument} from '../../generated/graphql'
import './ToleranceFormModal.css'
import {geoConfig} from '../../config/GeoConfig'
import {useOktaAuth} from '@okta/okta-react'

export interface ToleranceFormModalProps {
    isVisible: boolean
    setVisibilityFormModal: (boolean) => void
    selectedTolerance: Tolerance
}

const formId = 'toleranceForm'

const emptyForm = {
    soldToNbr: '',
    soldToName: '',
    group: '',
    fullTolerance: '',
    exclusion: ''
}

const toleranceOptions = [
    {
        value: true,
        label: 'Yes'
    },
    {
        value: false,
        label: 'No'
    }
]

const required = {required: true}

const useToleranceForm = (initialData) => {
    const _formData = useMemo(() => {
        const {
            soldToNbr = emptyForm.soldToNbr,
            soldToName = emptyForm.soldToName,
            group = emptyForm.group,
            fullTolerance = emptyForm.fullTolerance,
            exclusion = emptyForm.exclusion,
        } = initialData ?? {}

        return initialData ?
            {
                soldToNbr,
                soldToName,
                group,
                fullTolerance: toleranceOptions.find(t => t.value === fullTolerance),
                exclusion
            } : emptyForm
    }, [initialData])

    const {reset, ...restForm} = useForm({
        defaultValues: _formData
    })

    useEffect(() => {
        // Form state gets refreshed everytime another tolerance is selected
        reset(_formData)
    }, [initialData, reset, _formData])

    return {...restForm}
}

export function ToleranceFormModal({isVisible, selectedTolerance, setVisibilityFormModal}: ToleranceFormModalProps) {

    const {authState} = useOktaAuth()
    const {control, handleSubmit} = useToleranceForm(selectedTolerance)
    const [showSuccessSnack, setShowSuccessSnack] = useState(false)
    const [showErrorSnack, setShowErrorSnack] = useState(false)

    const [updateToleranceMutation] = useMutation(UpdateToleranceConfigDocument, {
        refetchQueries: [
            'searchTolerances'
        ]
    })

    const [insertToleranceMutation] = useMutation(InsertToleranceConfigDocument, {
        refetchQueries: [
            'searchTolerances'
        ]
    })

    const updateTolerance = (data) => {
        updateToleranceMutation({
            variables: {
                updateToleranceInput: {
                    soldToNbr: data.soldToNbr,
                    fullTolerance: data.fullTolerance.value,
                    geoAllocation: geoConfig.getGeo(),
                    user: authState?.idToken?.claims?.email
                },
                geoAllocation: geoConfig.getGeo()
            },
            onCompleted: () => {
                setShowSuccessSnack(true)
            },
            onError: () => {
                setShowErrorSnack(true)
            }
        }).then(() => {
            setVisibilityFormModal(false)
        })
    }

    const insertTolerance = (data) => {
        insertToleranceMutation({
            variables: {
                toleranceConfig: {
                    ...data,
                    fullTolerance: data.fullTolerance.value,
                    geoAllocation: geoConfig.getGeo(),
                    user: authState?.idToken?.claims?.email
                },
                geoAllocation: geoConfig.getGeo()
            },
            onCompleted: () => {
                setShowSuccessSnack(true)
            },
            onError: () => {
                setShowErrorSnack(true)
            }
        }).then(() => {
            setVisibilityFormModal(false)
        })
    }

    const handleSubmitForm = (data) => {
        selectedTolerance
            ? updateTolerance(data)
            : insertTolerance(data)
    }

    const header = <div>
        <Text font="title-3" className="eds-spacing--mb-12">
            {
                selectedTolerance
                    ? `Update Sold to Customer - ${selectedTolerance.soldToNbr}`
                    : 'Create new Sold To Customer'
            }
        </Text>
    </div>

    const footer = <div className="flex-row content-end w-100">
        <div>
            <Button className="eds-spacing--mr-32" variant="secondary" size="small"
                    onClick={() => setVisibilityFormModal(false)}>
                Cancel
            </Button>
        </div>

        <div>
            <Button variant="primary" type="submit" form={formId} size="small">
                {selectedTolerance ? 'Update' : 'Create'}
            </Button>
        </div>
    </div>

    const form = <form id={formId} className="tolerance-form flex-column content-space-between h-100"
                       onSubmit={handleSubmit(handleSubmitForm)}>
        <TextField name="soldToNbr" control={control} disabled={!!selectedTolerance} rules={required} label="Sold To Number"/>
        <TextField name="soldToName" control={control} disabled={!!selectedTolerance} rules={required} label="Sold To Name"/>
        <TextField name="group" control={control} disabled={!!selectedTolerance} label="Group"/>
        <Dropdown  name="fullTolerance" control={control} options={toleranceOptions} rules={required} label="Full tolerant"/>
        <TextField name="exclusion" control={control} disabled={!!selectedTolerance} label="Exclusion"/>
    </form>

    return (
        <div>

            <Modal isOpen={isVisible} headerSlot={header} footerSlot={footer}
                   onDismiss={() => setVisibilityFormModal(false)}>
                {form}
            </Modal>
            {showErrorSnack &&
                <Snack id="1" status="error" onDismiss={() => setShowErrorSnack(false)} className="snack__overlay"
                       autoDismissDuration={5000}>
                    <p className="eds-type--body-2">Something went wrong
                        with {selectedTolerance ? 'updating' : 'creating'} the Sold To Customer</p>
                </Snack>
            }

            {
                showSuccessSnack &&
                <Snack id="1" status="success" onDismiss={() => setShowSuccessSnack(false)} className="snack__overlay"
                       autoDismissDuration={5000}>
                    <p className="eds-type--body-2">Sold To Customer has
                        been {selectedTolerance ? 'updated' : 'created'}</p>
                </Snack>
            }
        </div>
    )
}
