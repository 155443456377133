import {geoConfig} from '../../config/GeoConfig'

const emeaInventoryReadAccess = 'App.global.returnsPlatform.emea.inventory.read'
const emeaFinanceReadAccess = 'App.global.returnsPlatform.emea.finance.read'
const emeaToleranceReadAccess = 'App.global.returnsPlatform.emea.tolerance.read'
const emeaToleranceEditAccess = 'App.global.returnsPlatform.emea.tolerance.edit'

const aplaInventoryReadAccess = 'App.global.returnsPlatform.apla.inventory.read'
const aplaFinanceReadAccess = 'App.global.returnsPlatform.apla.finance.read'
const aplaToleranceReadAccess = 'App.global.returnsPlatform.apla.tolerance.read'
const aplaToleranceEditAccess = 'App.global.returnsPlatform.apla.tolerance.edit'

const EMEA_REGION = 'EMEA'
const APLA_REGION = 'APLA'

function extractAuthGroups(authState) {
    return authState?.idToken?.claims?.groups
}

export function hasInventoryEmeaReadAccess(authState) {
    const groups = extractAuthGroups(authState)
    return groups != null && groups.includes(emeaInventoryReadAccess)
}

export function hasFinanceEmeaReadAccess(authState) {
    const groups = extractAuthGroups(authState)
    return groups != null && groups.includes(emeaFinanceReadAccess)
}

export function hasToleranceEmeaReadAccess(authState) {
    const groups = extractAuthGroups(authState)
    return groups != null &&
        (groups.includes(emeaToleranceReadAccess) || groups.includes(emeaToleranceEditAccess))
}

export function hasToleranceEmeaEditAccess(authState) {
    const groups = extractAuthGroups(authState)
    return groups != null && groups.includes(emeaToleranceEditAccess)
}

export function hasInventoryAplaReadAccess(authState) {
    const groups = extractAuthGroups(authState)
    return groups != null && groups.includes(aplaInventoryReadAccess)
}

export function hasFinanceAplaReadAccess(authState) {
    const groups = extractAuthGroups(authState)
    return groups != null && groups.includes(aplaFinanceReadAccess)
}

export function hasToleranceAplaReadAccess(authState) {
    const groups = extractAuthGroups(authState)
    return groups != null &&
        (groups.includes(aplaToleranceReadAccess) || groups.includes(aplaToleranceEditAccess))
}

export function hasToleranceAplaEditAccess(authState) {
    const groups = extractAuthGroups(authState)
    return groups != null && groups.includes(aplaToleranceEditAccess)
}

export function hasReturnsReadAccess(authState) {
    return hasInventoryReadAccess(authState) && hasFinanceReadAccess(authState)
}

export function hasInventoryReadAccess(authState) {
    if (isEmeaRegion()) {
        return hasInventoryEmeaReadAccess(authState)
    } else if (isAplaRegion()) {
        return hasInventoryAplaReadAccess(authState)
    }
}

export function hasFinanceReadAccess(authState) {
    if (isEmeaRegion()) {
        return hasFinanceEmeaReadAccess(authState)
    } else if (isAplaRegion()) {
        return hasFinanceAplaReadAccess(authState)
    }
}

export function hasToleranceReadAccess(authState) {
    if (isEmeaRegion()) {
        return hasToleranceEmeaReadAccess(authState)
    } else if (isAplaRegion()) {
        return hasToleranceAplaReadAccess(authState)
    }
}

export function hasToleranceEditAccess(authState) {
    if (isEmeaRegion()) {
        return hasToleranceEmeaEditAccess(authState)
    } else if (isAplaRegion()) {
        return hasToleranceAplaEditAccess(authState)
    }
}

function isEmeaRegion(){
    return geoConfig.getGeo() === EMEA_REGION
}

function isAplaRegion(){
    return geoConfig.getGeo() === APLA_REGION
}