import {useMemo} from 'react'
import {Link, Text} from '@nike/eds'
import {Link as RouterLink} from 'react-router-dom'
import {TableLayout, useServerDataTable} from '../../shared/table'
import {InfoSnack} from '../../shared/component/InfoSnack'
import {date} from '../../shared/datautils/formatter'
import {TableHeaderWithTooltip} from '../../shared/component/TableHeaderWithTooltip'

const generateReturnDetailLink = (item) => {
    let rsoNumber = encodeURIComponent(item.value);
    let plantCode = encodeURIComponent(item?.row?.original?.plantCode);
    return `/returns-detail/${rsoNumber}?plantCode=${plantCode}`;
}

const useFinanceOverviewTable = (
    queryState,
    gotoPage,
    setPageSize,
    toggleSortBy,
    isSuccess,
    data
) => {
    const columns = useMemo(
        () => [
            {
                Header: () => <div>RSO Number</div>,
                accessor: 'returnSalesOrderNumber',
                Cell: item => (
                    <div
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            maxWidth: '100px',
                        }}
                        title={item.value}
                    >
                        <Link
                            style={{
                                display: 'inline',
                                fontFamily: 'inherit',
                                fontSize: 'inherit',
                            }}
                            as={RouterLink}
                            to={generateReturnDetailLink(item)}
                        >
                            {item.value}
                        </Link>
                    </div>
                ),
            },
            {
                Header: () => <div>Sold to</div>,
                accessor: 'soldToCode',
                Cell: item => <div>{item.value}{item?.row?.original?.soldToName ? " - " + item?.row?.original?.soldToName :  ""}</div>,
            },
            {
                Header: () => <div>Ship to</div>,
                accessor: 'shipToCode',
                Cell: item => <div>{item.value}{item?.row?.original?.shipToName ? " - " + item?.row?.original?.shipToName :  ""}</div>,
            },
            {
                Header: () => <div>Full tolerant</div>,
                accessor: 'fullTolerance',
                Cell: item => <div>{item.value ? 'Yes' : 'No'}</div>,
            },
            {
                Header: () => <div>RSO status</div>,
                accessor: 'status',
                Cell: item => <div>{item.value}</div>,
            },
            {
                Header: () => <div>Plant code</div>,
                accessor: 'plantCode',
                Cell: item => <div>{item.value}</div>,
            },
            {
                Header: () => <div>Crediting level</div>,
                accessor: 'creditingOnProductLevel',
                Cell: item => <div>{item.value ? 'Product' : 'Item'}</div>,
            },
            {
                Header: () => <TableHeaderWithTooltip headerContent="Expected units" toolTipContent="Total amount of expected units (coming from the RSO creation information)" />,
                accessor: 'units.expectedUnits',
                Cell: item => <div>{item.value}</div>,
            },
            {
                Header: () => <TableHeaderWithTooltip headerContent="Received units" toolTipContent="Total amount of units retrieved in the Node. The C-graded units are included"/>,
                accessor: 'units.receivedUnits',
                Cell: item => <div>{item.value}</div>,
            },
            {
                Header: () => <TableHeaderWithTooltip headerContent="Total C-grade units" toolTipContent="Total amount of C-grade units. The overages and unexpected units are included" />,
                accessor: 'units.totalCGradeUnits',
                Cell: item => <div>{item.value}</div>,
            },
            {
                Header: () => <div>Customer requested delivery date</div>,
                accessor: 'customerRequestedDeliveryDate',
                Cell: item => <div>{item.value ? date(item.value, false) : '-'}</div>,
            },
            {
                Header: () => <div>Approval date</div>,
                accessor: 'approvalDate',
                Cell: item => <div>{item.value ? date(item.value, false) : '-'}</div>,
            },
            {
                Header: () => <div>RSO Received at Node datetime</div>,
                accessor: 'receivedNodeDateTime',
                Cell: item => <div>{item.value ? date(item.value, true) : '-'}</div>,
            }, {
                Header: () => <div>RSO Closure by Node datetime</div>,
                accessor: 'closureNodeDateTime',
                Cell: item => <div>{item.value ? date(item.value, true) : '-'}</div>,
            },
        ],
        []
    )

    return useServerDataTable({
        queryState,
        overrides: {
            gotoPage,
            setPageSize,
            toggleSortBy,
        },
        columns,
        isSuccess,
        data,
    })
}

export function ReturnsOverviewTable({
                                     queryState, gotoPage, setPageSize,
                                     toggleSortBy, isFetching, isSuccess,
                                     data, clearFilter
                                 }: FinanceOverviewTableProps) {
    const tableInstance = useFinanceOverviewTable(
        queryState,
        gotoPage,
        setPageSize,
        toggleSortBy,
        isSuccess,
        data
    )
    return (

        !isFetching && tableInstance.rows.length === 0
            ? <InfoSnack className="eds-spacing--mt-16">
                <Text font="title-5" as="h5">
                    No records were found that match your filter criteria
                </Text>
                <span className="text__clickable" onClick={clearFilter}>Clear Filter</span>
            </InfoSnack>
            : <TableLayout
                {...tableInstance}
                isFetching={isFetching}
                className="-striped -highlight"
            />
    )
}

export interface FinanceOverviewTableProps {
    queryState: any,
    gotoPage: (pageNumber) => void,
    setPageSize: (pageSize) => void,
    toggleSortBy: (id, sortByDesc) => void,
    clearFilter: () => void,
    isFetching: boolean,
    isSuccess: boolean,
    data: object,
    totalResources?: number,
}
