import './TableLayout.css'
import {Text, Icon, Spinner, Button, Table, TableHeading, TableCell, Tooltip} from '@nike/eds'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import {MenuItem} from '@mui/material'
import {Row, TableInstance} from 'react-table'

const defaultPageSizeOptions = [10, 25, 50, 100, 200, 500]


interface TableLayoutProps extends TableInstance {
    pageSizeOptions?: number[]
    totalResources?: number
    isFetching?: boolean
    isError?: boolean
    isPaginated?: boolean
    rowsWithoutPagination?: Array<Row>
    loadingDataName?: String
}

export const TableLayout = ({
                                getTableProps,
                                headerGroups,
                                getTableBodyProps,
                                prepareRow,
                                page,
                                canPreviousPage,
                                canNextPage,
                                pageCount,
                                gotoPage,
                                setPageSize,
                                toggleSortBy,
                                isPaginated = true,
                                state: {pageIndex, pageSize},
                                pageSizeOptions = defaultPageSizeOptions,
                                totalResources,
                                isFetching,
                                rowsWithoutPagination = [],
                                loadingDataName = "returns"
                            }: TableLayoutProps): JSX.Element => {
    const changePageSize = (event: SelectChangeEvent<string>) => {
        setPageSize(event.target.value as unknown as number)
        gotoPage(0)
    }
    // Render the UI for your table
    let isFewRecords = false
    if (totalResources < pageSize) {
        isFewRecords = true
    }

    let isLastPage = false
    if (pageIndex === pageCount - 1) {
        isLastPage = true
    }

    return (
        <div>
            {isFetching ? (
                <div className="flex-row flex-wrap content-center justify-items-center">
                    <div style={{marginRight: 'var(--eds-space-8)'}}>
                        <Spinner size="large"/>
                    </div>
                    <div>
                        <Text>Loading {loadingDataName}</Text>
                    </div>
                </div>
            ) : (
                <div>
                    {isPaginated &&
                        <div
                            className="flex-row flex-wrap content-space-between justify-items-center TableLayoutPagination">
                            <div style={{visibility: 'hidden'}}>
                                <Text font="body-2" as="span">
                                    {totalResources === 0 ? 0 : pageSize * (pageIndex + 1) - (pageSize - 1)} -{' '}
                                    {isFewRecords
                                        ? totalResources
                                        : isLastPage
                                            ? totalResources
                                            : pageSize * (pageIndex + 1)}{' '}
                                    of {totalResources} results
                                </Text>
                            </div>

                            <div className="flex-row flex-wrap content-space-between justify-items-center">
                                <Tooltip
                                    bodySlot={<Text font="body-1">First Page</Text>}
                                    isDark={true}
                                    placement={'top'}
                                >
                                    <Button
                                        onClick={() => gotoPage(0)}
                                        disabled={pageIndex === 0}
                                        size="small"
                                        variant="ghost"
                                    >
                                        <Icon
                                            name="CaretLeft"
                                            style={{marginRight: '-8px'}}
                                            className={pageIndex === 0 ? 'disabled' : ''}
                                        />
                                        <Icon
                                            name="CaretLeft"
                                            style={{marginLeft: '-8px'}}
                                            className={pageIndex === 0 ? 'disabled' : ''}
                                        />
                                    </Button>
                                </Tooltip>

                                <Tooltip
                                    bodySlot={<Text font="body-1">Previous Page</Text>}
                                    isDark={true}
                                    placement={'top'}
                                >
                                    <Button
                                        onClick={() => gotoPage(pageIndex - 1)}
                                        disabled={!canPreviousPage}
                                        size="small"
                                        className="button-previous"
                                        variant="ghost"
                                        style={{marginRight: 'var(--eds-space-16)'}}
                                    >
                                        <Icon name="CaretLeft" className={!canPreviousPage ? 'disabled' : ''}/>
                                    </Button>
                                </Tooltip>
                                <Text font="body-2" as="span" style={{marginTop: '-4px'}}>
                                    {pageCount === 0 ? 0 : pageIndex + 1} of {pageCount} pages
                                </Text>

                                <Tooltip
                                    bodySlot={<Text font="body-1">Next Page</Text>}
                                    isDark={true}
                                    placement={'top'}
                                >
                                    <Button
                                        onClick={() => gotoPage(pageIndex + 1)}
                                        disabled={!canNextPage}
                                        size="small"
                                        className="button-next"
                                        variant="ghost"
                                        style={{marginLeft: 'var(--eds-space-16)'}}
                                    >
                                        <Icon name="CaretRight" className={!canNextPage ? 'disabled' : ''}/>
                                    </Button>
                                </Tooltip>

                                <Tooltip
                                    bodySlot={<Text font="body-1">Last Page</Text>}
                                    isDark={true}
                                    placement={'top'}
                                >
                                    <Button
                                        onClick={() => gotoPage(pageCount - 1)}
                                        disabled={pageIndex === pageCount - 1 || pageCount === 0}
                                        size="small"
                                        variant="ghost"
                                    >
                                        <Icon
                                            name="CaretRight"
                                            style={{marginRight: '-8px'}}
                                            className={pageIndex === pageCount - 1 || pageCount === 0 ? 'disabled' : ''}
                                        />
                                        <Icon
                                            name="CaretRight"
                                            style={{marginLeft: '-8px'}}
                                            className={pageIndex === pageCount - 1 || pageCount === 0 ? 'disabled' : ''}
                                        />
                                    </Button>
                                </Tooltip>
                            </div>
                            <div style={{visibility: 'hidden'}}>
                                <Text font="body-2" as="span" style={{marginRight: 'var(--eds-space-4)'}}>
                                    Rows per page:{' '}
                                </Text>
                                <Select
                                    value={pageSize?.toString()}
                                    onChange={changePageSize}
                                    style={{marginRight: 'var(--eds-space-16)'}}
                                >
                                    {pageSizeOptions.map(it => (
                                        <MenuItem key={it} value={it}>
                                            {it}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    }
                    <div
                        style={{
                            overflowX: 'auto',
                        }}
                    >
                        <Table isDark={false} {...getTableProps()}>
                            <thead>
                            {
                                // Loop over the header rows
                                headerGroups.map(headerGroup => (
                                    // Apply the header row props
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            // Loop over the headers in each row
                                            headerGroup.headers.map(column => (
                                                // Apply the header cell props
                                                <TableHeading {...column.getHeaderProps()}>
                                                    {
                                                        // Render the header
                                                        //if column is sortable
                                                        column.canSort ? (
                                                            <div
                                                                {...column.getSortByToggleProps()}
                                                                onClick={() => {
                                                                    toggleSortBy(
                                                                        column.id,
                                                                        column.isSorted ? !column.isSortedDesc : false
                                                                    )
                                                                }}
                                                            >
                                                                <div
                                                                    className="flex-row flex-nowrap justify-items-center">
                                                                    <div
                                                                        style={{
                                                                            width: '95%',
                                                                        }}
                                                                    >
                                                                        {column.render('Header')}
                                                                    </div>
                                                                    <div>
                                                                        {/* Add a sort direction indicator */}
                                                                        {column.isSorted ? (
                                                                            column.isSortedDesc ? (
                                                                                <Icon
                                                                                    name="SortDescending"
                                                                                    size="m"
                                                                                    className="TableSortIconDesc"
                                                                                />
                                                                            ) : (
                                                                                <Icon
                                                                                    name="SortAscending"
                                                                                    size="m"
                                                                                    className="TableSortIconAsc"
                                                                                />
                                                                            )
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            //if column is not sortable
                                                            <div>{column.render('Header')}</div>
                                                        )
                                                    }
                                                </TableHeading>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                            </thead>
                            {/* Apply the table body props */}
                            <tbody {...getTableBodyProps()}>
                            {rowsWithoutPagination.length > 0 &&
                                // Loop over the table rows
                                rowsWithoutPagination.map(row => {
                                    // Prepare the row for display
                                    prepareRow(row)
                                    return (
                                        // Apply the row props
                                        <tr {...row.getRowProps()}>
                                            {
                                                // Loop over the rows cells
                                                row.cells.map(cell => {
                                                    // Apply the cell props
                                                    return (
                                                        <TableCell {...cell.getCellProps()}>
                                                            {
                                                                // Render the cell contents
                                                                cell.render('Cell')
                                                            }
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }

                            {page != null &&
                                // Loop over the table rows
                                page.map(row => {
                                    // Prepare the row for display
                                    prepareRow(row)
                                    return (
                                        // Apply the row props
                                        <tr {...row.getRowProps()}>
                                            {
                                                // Loop over the rows cells
                                                row.cells.map(cell => {
                                                    // Apply the cell props
                                                    return (
                                                        <TableCell {...cell.getCellProps()}>
                                                            {
                                                                // Render the cell contents
                                                                cell.render('Cell')
                                                            }
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                    </div>

                    {isPaginated &&
                        <div
                            className="flex-row flex-wrap content-space-between justify-items-center TableLayoutPagination">
                            <div>
                                <Text font="body-2" as="span">
                                    {totalResources === 0 ? 0 : pageSize * (pageIndex + 1) - (pageSize - 1)} -{' '}
                                    {isFewRecords
                                        ? totalResources
                                        : isLastPage
                                            ? totalResources
                                            : pageSize * (pageIndex + 1)}{' '}
                                    of {totalResources} results
                                </Text>
                            </div>

                            <div className="flex-row flex-wrap content-space-between justify-items-center">
                                <Tooltip
                                    bodySlot={<Text font="body-1">First Page</Text>}
                                    isDark={true}
                                    placement={'top'}
                                >
                                    <Button
                                        onClick={() => gotoPage(0)}
                                        disabled={pageIndex === 0}
                                        size="small"
                                        variant="ghost"
                                    >
                                        <Icon
                                            name="CaretLeft"
                                            style={{marginRight: '-8px'}}
                                            className={pageIndex === 0 ? 'disabled' : ''}
                                        />
                                        <Icon
                                            name="CaretLeft"
                                            style={{marginLeft: '-8px'}}
                                            className={pageIndex === 0 ? 'disabled' : ''}
                                        />
                                    </Button>
                                </Tooltip>

                                <Tooltip
                                    bodySlot={<Text font="body-1">Previous Page</Text>}
                                    isDark={true}
                                    placement={'top'}
                                >
                                    <Button
                                        onClick={() => gotoPage(pageIndex - 1)}
                                        disabled={!canPreviousPage}
                                        size="small"
                                        className="button-previous"
                                        variant="ghost"
                                        style={{marginRight: 'var(--eds-space-16)'}}
                                    >
                                        <Icon name="CaretLeft" className={!canPreviousPage ? 'disabled' : ''}/>
                                    </Button>
                                </Tooltip>
                                <Text font="body-2" as="span" style={{marginTop: '-4px'}}>
                                    {pageCount === 0 ? 0 : pageIndex + 1} of {pageCount} pages
                                </Text>

                                <Tooltip
                                    bodySlot={<Text font="body-1">Next Page</Text>}
                                    isDark={true}
                                    placement={'top'}
                                >
                                    <Button
                                        onClick={() => gotoPage(pageIndex + 1)}
                                        disabled={!canNextPage}
                                        size="small"
                                        className="button-next"
                                        variant="ghost"
                                        style={{marginLeft: 'var(--eds-space-16)'}}
                                    >
                                        <Icon name="CaretRight" className={!canNextPage ? 'disabled' : ''}/>
                                    </Button>
                                </Tooltip>

                                <Tooltip
                                    bodySlot={<Text font="body-1">Last Page</Text>}
                                    isDark={true}
                                    placement={'top'}
                                >
                                    <Button
                                        onClick={() => gotoPage(pageCount - 1)}
                                        disabled={pageIndex === pageCount - 1 || pageCount === 0}
                                        size="small"
                                        variant="ghost"
                                    >
                                        <Icon
                                            name="CaretRight"
                                            style={{marginRight: '-8px'}}
                                            className={pageIndex === pageCount - 1 || pageCount === 0 ? 'disabled' : ''}
                                        />
                                        <Icon
                                            name="CaretRight"
                                            style={{marginLeft: '-8px'}}
                                            className={pageIndex === pageCount - 1 || pageCount === 0 ? 'disabled' : ''}
                                        />
                                    </Button>
                                </Tooltip>
                            </div>
                            <div>
                                <Text font="body-2" as="span" style={{marginRight: 'var(--eds-space-4)'}}>
                                    Rows per page:{' '}
                                </Text>
                                <Select
                                    value={pageSize?.toString()}
                                    onChange={changePageSize}
                                    style={{marginRight: 'var(--eds-space-16)'}}
                                >
                                    {pageSizeOptions.map(it => (
                                        <MenuItem key={it} value={it}>
                                            {it}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    }
                </div>
            )}
        </div>
    )
}
