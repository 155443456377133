import {Popover, StatusIcon} from '@nike/eds'
import {downloadConfig} from './DownloadConfig'
import React, {Dispatch, SetStateAction} from 'react'

interface ErrorPopoverProps extends React.HTMLAttributes<HTMLElement> {
    children?: React.ReactNode,
    isPopoverVisible: boolean,
    setIsPopoverVisible: Dispatch<SetStateAction<boolean>>
}

export const ErrorPopoverComponent: React.FC<ErrorPopoverProps> = ({children, isPopoverVisible, setIsPopoverVisible}) => {
    
    const header =
    <h1 className='eds-type--title-5'>
        <StatusIcon status='error' statusIconSize='s' enableFocus /> Error message
    </h1>

    const body =
    <p style={{ width: '256px' }}>
        <p>Something went wrong.</p>
        <p>Please, try again or contact Boomerang team for assistance.</p>
    </p>

    const onClickOutsidePopover = () => {
        setIsPopoverVisible(false)
        downloadConfig.clearLoadingButton()
    }

    return (
        <Popover
            isOpen={isPopoverVisible}
            onClickOutside={onClickOutsidePopover}
            headerSlot={header}
            bodySlot={body}
        >
            {children}
        </Popover>
    )
}
