import {Button, Modal, Snack, Text} from '@nike/eds'
import {Tolerance} from '../../model/ToleranceOverviewModels'
import {useMutation,} from '@apollo/client'
import {DeleteToleranceConfigDocument} from '../../generated/graphql'
import {useState} from 'react'
import {geoConfig} from '../../config/GeoConfig';
import {useOktaAuth} from "@okta/okta-react";

export interface ToleranceDeleteModalProps {
    isVisible: boolean,
    setVisibilityDeleteModal: (boolean) => void
    selectedTolerance: Tolerance
}


export function ToleranceDeleteModal({
                                         isVisible,
                                         selectedTolerance,
                                         setVisibilityDeleteModal
                                     }: ToleranceDeleteModalProps) {

    const [deleteToleranceMutation] = useMutation(DeleteToleranceConfigDocument, {
        refetchQueries: [
            'searchTolerances'
        ]
    })
    const {authState} = useOktaAuth()
    const [showSuccessSnack, setShowSuccessSnack] = useState(false)
    const [showErrorSnack, setShowErrorSnack] = useState(false)

    const deleteTolerance = () => {
        deleteToleranceMutation({
            variables: {
                deleteToleranceInput: {
                    soldToNbr: selectedTolerance.soldToNbr,
                    geoAllocation: geoConfig.getGeo(),
                    user: authState?.idToken?.claims?.email
                },
                geoAllocation: geoConfig.getGeo()
            },
            onCompleted: () => {
                setShowSuccessSnack(true)
            },
            onError: () => {
                setShowErrorSnack(true)
            }
        }).then(() => {
            setVisibilityDeleteModal(false)
        })
    }
    const header = <div>
        <Text font="title-3" className="eds-spacing--mb-12">Delete a Sold To Customer</Text>
    </div>

    const footer = <div className="flex-row content-end w-100">
        <div>
            <Button className="eds-spacing--mr-32" variant="secondary" size="small"
                    onClick={() => setVisibilityDeleteModal(false)}>
                Cancel
            </Button>
        </div>

        <div>
            <Button onClick={deleteTolerance} variant="primary"
                    type="submit" size="small">
                Delete
            </Button>
        </div>
    </div>

    return (
        <div>
            <Modal isOpen={isVisible} headerSlot={header}
                   onDismiss={() => setVisibilityDeleteModal(false)} footerSlot={footer}>
                <Text font="body-2" className="eds-spacing--mb-12">Are you sure you want to
                    delete {selectedTolerance.soldToNbr} - {selectedTolerance.soldToName}? </Text>
            </Modal>

            {showErrorSnack &&
                <Snack id="1" status="error" onDismiss={() => setShowErrorSnack(false)} className="snack__overlay"
                       autoDismissDuration={5000}>
                    <p className="eds-type--body-2">Something went wrong with deleting the Sold To Customer</p>
                </Snack>
            }

            {
                showSuccessSnack &&
                <Snack id="1" status="success" onDismiss={() => setShowSuccessSnack(false)} className="snack__overlay"
                       autoDismissDuration={5000}>
                    <p className="eds-type--body-2">Sold To Customer has been deleted</p>
                </Snack>
            }


        </div>

    )
}
