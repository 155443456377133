import { useState } from 'react'
import { Divider } from '@mui/material'
import { useQuery } from '@apollo/client'
import { useQueryState } from '../../shared/hooks/useQueryState'
import { ChipValue } from '../../shared/layout/Chips'
import { ReturnsOverviewTable, FinanceOverviewTableProps } from './ReturnsOverviewTable'
import { ReturnsOverviewFilter } from './ReturnsOverviewFilter'
import { ReturnsOverviewFilterChips } from './ReturnsOverviewFilterChips'
import { SearchFinanceViewDocument } from '../../generated/graphql'
import { ApolloResult } from '../../shared/component/ApolloResult'
import { FinanceOverviewFilterFields } from '../../model/FinanceOverviewModels'
import { PlantCodeUpdateUtil } from "../../shared/component/PlantCodeUpdateUtil";
import {returnsOverviewFilterStore} from "./ReturnsOverviewFilterStore";
import {geoConfig} from "../../config/GeoConfig";

export function ReturnsOverview () {
    const {plantCodes, plantCodeRequestErrors, plantCodeRequestNetworkStatus} = PlantCodeUpdateUtil()

    const getDefaultQueryState = () => {
        const yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date())
        const financeFiltersData = returnsOverviewFilterStore.getFinanceFilterStoredData()

        if (financeFiltersData) {
            return {
                filter: financeFiltersData
            }
        } else {
            return {
                filter: {
                    approvalDateFrom: yesterday.toISOString().substring(0, 10)
                }
            }
        }
    }

    const {
        state: queryState,
        setFilter,
        gotoPage,
        setPageSize,
        toggleSortBy,
        clearFilter,
    } = useQueryState<FinanceOverviewFilterFields>({ ...getDefaultQueryState() })

    const { loading: isFetching, error, data, networkStatus } = useQuery(SearchFinanceViewDocument, {
        skip: !plantCodes,
        variables: {
            financeViewInput: {
                from: queryState.pageNumber * queryState.pageSize,
                size: queryState.pageSize,
                sorting: queryState.sortBy,
                ...queryState?.filter,
                plantCodes: queryState.filter?.plantCodes || plantCodes
            },
            geoAllocation: geoConfig.getGeo()
        }
    })

    const financeOverviewTableProps: FinanceOverviewTableProps = {
        queryState,
        gotoPage,
        setPageSize,
        toggleSortBy,
        isSuccess: !error && !isFetching,
        isFetching,
        clearFilter,
        data: {
            data: data ? data.searchFinanceView.financeViewDocuments : [],
            ...data?.searchFinanceView?.paging
        }
    }

    const [expandFilterForm, setExpandFilterForm] = useState(false)

    const onClickFilter = (filterFormData: FinanceOverviewFilterFields) => {
        returnsOverviewFilterStore.setFinanceFilterStoredData(filterFormData)
        setFilter(filterFormData)
    }
    const handleCloseFilterForm = () => {
        setExpandFilterForm(false)
    }

    const handleChipDelete = (value: ChipValue) => {
        const currentValue = queryState.filter?.[value.field]
        if (currentValue === undefined || queryState.filter === undefined) {
            return
        }
        const currentFilter = queryState.filter
        let newValue
        if (Array.isArray(currentValue)) {
            const values = currentValue.filter((v, i) => i !== value.index)
            newValue = values?.length !== 0 ? values : undefined
        } else {
            newValue = undefined
        }
        returnsOverviewFilterStore.updateFinanceFilterStoredData(value.field, newValue)
        setFilter({
            ...currentFilter,
            [value.field]: newValue,
        })
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 'var(--eds-space-8)'
            }}
        >
            <ApolloResult networkStatus={!plantCodes ? plantCodeRequestNetworkStatus : networkStatus} error={!plantCodes ? plantCodeRequestErrors : error}>
                <ReturnsOverviewFilterChips filter={queryState.filter} onChipDelete={handleChipDelete}
                                            clearFilter={clearFilter} expandFilterForm={setExpandFilterForm}/>
                <Divider
                    style={{
                        width: '98%',
                        margin: 'auto',
                        borderBottomWidth: 2,
                    }}
                />
                <ReturnsOverviewTable  {...financeOverviewTableProps}></ReturnsOverviewTable>
                <ReturnsOverviewFilter
                    currentFilter={queryState.filter}
                    onClickFilter={onClickFilter}
                    expand={expandFilterForm}
                    onCloseSidePanel={handleCloseFilterForm}
                ></ReturnsOverviewFilter>
            </ApolloResult>
        </div>
    )
}
